.setting-container {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 55px;
  .setting-info-left {
    .your-plan-info {
      display: flex;
      flex-flow: wrap;
      width: 100%;
      .left-content {
        width: 50%;
        display: flex;
        flex-flow: wrap;

        h2 {
          margin: 0;
        }

        .base-info {
          width: 100%;
          padding-top: 16px;

          .base {
            width: 100%;
            font-weight: 400;
            font-size: 24px;
            color: $black;
          }

          .text {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            color: $black;
          }
        }
      }

      .right-content {
        width: 50%;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;

        .subscription {
          width: 100%;
          display: flex;
          color: $black;
          font-size: 22px;
          padding-top: 30px;
          justify-content: flex-end;

          .ammount {
            font-weight: 400;
            font-size: 28px;
            position: relative;
            top: -5px;
          }
        }
      }
    }
    .payment-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 20px;

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }
      .heading {
        font-weight: 600;
        font-size: 26px;
        color: $black;
        width: 100%;
        padding-bottom: 10px;
        @include large {
          font-size: 32px;
        }
      }

      ._payment-form {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        margin-bottom: 21px;
        min-height: 62px;
        @include desktop2 {
          margin-bottom: 25px;
        }
        @include large {
          min-height: 50px;
          margin-bottom: 17px;
        }

        .input-field {
          width: calc(100% - 70px);
          padding-right: 20px;
        }

        .btn-editpay {
          width: 25px;
          text-align: center;
          margin-right: 20px;
          padding: 0;
        }

        .btn-delete-pay {
          width: 25px;
          text-align: center;
          padding: 0;
        }
      }
      .payment-outerform {
        width: 65%;
        margin: 0 auto;
      }
    }
    .setting-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      .task-manage-team-wrapper .table-content table thead{
        background-color: transparent;
      }

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }
      .heading {
        font-weight: 600;
        font-size: 26px;
        color: $black;
        width: 100%;
        padding-bottom: 10px;
        @include large {
          font-size: 32px;
        }
      }
      .yourplan {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        .planname {
          width: 60%;
          font-weight: 600;
          font-size: 18px;
          color: $black;
          @include large {
            font-size: 24px;
          }
        }
        .seebtn-info {
          width: 40%;
        }

        .btn-seeAll {
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          color: $tangerine;
          border: 0;

          @include desktop2 {
            font-size: 16px;
          }

          @include large {
            font-size: 18px;
          }

          &:hover {
            color: $tangerine;
          }
        }
      }
    }
    .change-password-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 20px;

      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }
      .heading {
        font-weight: 600;
        font-size: 26px;
        color: $black;
        width: 100%;
        padding-bottom: 15px;
        @include large {
          font-size: 32px;
        }
      }
      .change-password-form {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        .btn-change-pass {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn-primary {
            width: 220px;
          }
        }
        .control-form-inf {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          align-items: center;
          margin-bottom: 15px;
          .lable {
            width: 40%;
            font-weight: 600;
            font-size: 16px;
          }
          .inputform {
            width: 60%;
            input {
              background-color: white;
              height: 40px;
              padding: 0 10px;
              border: 0;
              border-radius: 5px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .app-setting-content {
    .heading {
      h2 {
        font-weight: 600;
        font-size: 26px;
        color: $black;
        width: 100%;
        padding-bottom: 15px;
        @include large {
          font-size: 32px;
        }
      }
    }
    .notification-password-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 10px;
      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }
      .form-switch .form-check-input {
        width: 45px;
        border: 0;
        background-color: #b1b7c0;
        background-image: url("/assets/images/download.png");
      }
      .form-check-input:checked {
        background-color: $tangerine;
        border: 0;
      }

      form {
        margin: 0;
      }
      .subheading {
        font-weight: 600;
        font-size: 22px;
        color: $black;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        align-items: center;

        @include large {
          font-size: 26px;
        }
        h2 {
          font-weight: 600;
        }
      }
      .repeater {
        width: 100%;
        margin-bottom: 10px;
        .form-switch {
          display: flex;
          justify-content: flex-end;
        }
      }
      .sub-notification-text {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        width: 100%;
        align-items: center;
        h3 {
          font-weight: 600;
          font-size: 16px;
          color: $black;
          padding-left: 30px;
          @include large {
            font-size: 20px;
          }
        }
      }
    }
    .more-password-info {
      background: #f6f8fb;
      border-radius: 12px;
      padding: 25px 25px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      align-items: flex-start;
      margin-top: 20px;
      @include large {
        border-radius: 16px;
        padding: 33px 48px;
      }
      .btn-seeAll {
        font-weight: 600;
        font-size: 15px;
        color: $tangerine;
        border: 0;
        margin-left: 30px;
        margin-top: 12px;

        @include desktop2 {
          font-size: 17px;
        }

        @include large {
          font-size: 18px;
        }

        &:hover {
          color: $tangerine;
        }
      }
      .heading {
        h3 {
          font-weight: 600;
          font-size: 22px;
          color: $black;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px;

          @include large {
            font-size: 26px;
          }
        }
      }
      .active-user-content {
        width: 100%;
      }
      .more-language {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        .dropdown {
          height: 46px;
          // width: 60%;
        }
        .dropdown button {
          border: solid 1px #ccc;
          border-radius: 6px;
          height: 46px;
          // width: 60%;
          padding: 0 10px;
          color: $blue;
          // font-size: 15px;
        }

        label {
          font-weight: 600;
          font-size: 16px;
          color: $black;
          padding-left: 30px;
          width: 40%;
          @include large {
            font-size: 20px;
          }
        }
      }
    }
  }
}
