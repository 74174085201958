.task-manage-team-wrapper {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-top: 30px;

  @include large {
    margin-top: 50px;
  }

  h2 {
    font-weight: 600;
    font-size: 22px;
    color: $black;
    margin-bottom: 25px;

    @include large {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  .search-bar {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;

    form {
      margin: 0;
      position: relative;
      display: flex;
      align-items: center;
    }

    ._search {
      border: 0;
      position: absolute;
      right: 15px;
      background: transparent;
    }

    input {
      width: 550px;
      padding: 0 21px;
      border: 1px solid #ccd2db;

      @include small {
        width: 430px;
      }

      @include large {
        width: 648px;
        padding: 0 30px;
      }
    }
  }
  .table-scrollbar {
    height: calc(100vh - 345px);
    overflow: auto;
    width: 100%;
    margin-top: 30px;
  }

  .table-content {
    width: 100%;
    // overflow: auto;
    max-height: 100%;
    padding-right: 10px;
    overflow-x: scroll;
     .emailuser{
      width: 100%;
      word-wrap: break-word;
      width: 140px;
     }
    ._profile-img {
      width: 70px;
      height: 70px;
      position: relative;
      background-color: #ebedf0;
      border-radius: 50%;
      margin-right: 10px;

      @include large {
        width: 47px;
        height: 47px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      .badges {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: auto;
        height: auto;
        z-index: 1;
        max-width: 15px;
      }
    }
    .profile-info {
      display: flex;
      align-items: center;
      width: 100%;
      p{
        width: calc(100% - 50px);
        word-wrap: break-word;
        line-height: 16px;
      width: 100px;

      }
    }
    .filled {
      font-weight: 600;
      color: $tangerine;
    }
    .reminder {
      font-weight: 600;
      color: $blue;
      cursor: pointer;
    }
    .btn-invition {
      font-weight: 600;
      color: $blue;
      text-transform: uppercase;
      padding: 0;
      font-size: 14px;
      margin-right: 10px;
      cursor: pointer;
    }
    .btn-invition-deactive {
      font-weight: 600;
      color: $gray;
      text-transform: uppercase;
      padding: 0;
      font-size: 14px;
      margin-right: 10px;
    }
    .action-btn {
      width: 100%;
    }
    .edit {
      cursor: pointer;
      margin: 0 10px;
    }
    .delete {
      cursor: pointer;
      ._delete-icon-hover{
        display: none;
      }
    }
    table {
      width: 100%;
      border: 0;
      form {
        margin: 0;
        width: 100%;
      }
      .form-switch .form-check-input {
        width: 45px;
        border: 0;
        background-color: #b1b7c0;
        background-image: url("/assets/images/download.png");
      }
      .form-check-input:checked {
        background-color: $tangerine;
        border: 0;
      }
      &.historytable{
        thead {
          th {
            width: calc(100% / 7);
            &:last-child{
              width: 10%;
            }
          }
        } 
        tbody {
          td {
            width: calc(100% / 7);
            &:last-child{
              width: 10%;
            }

          }
        } 
      }
      &.manageteamtable{
        thead {
          th {
            width: calc(100% / 7);
            &:last-child{
              width: 260px;
              display: block;
            }
          }
        } 
        tbody {
          td {
            width: calc(100% / 7);
            &:last-child{
              width: 260px;
              display: block;
              padding-top: 35px;
            }

          }
        } 
      }

      thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 8;

        th {
          font-weight: 500;
          font-size: 12.6px;
          columns: $black;
          padding: 10px 13px;
          position: relative;
          padding-bottom: 25px;
          vertical-align: top;
          width: calc(100% / 9);
        
          .arrow {
            width: 10px;
            height: 12px;
            position: absolute;
            top: 10px;
            right: 4px;
          }
          img {
            vertical-align: top;
          }

          .toparrow {
            position: relative;
            top: 3px;
            cursor: pointer;
            &.active {
              filter: invert(75%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
            }
          }

          .downarrow {
            position: relative;
            bottom: 5px;
            cursor: pointer;
            &.active {
              filter: invert(75%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
            }
          }
        }
      }

      tbody {
        tr {
          background-color: #f6f8fb;
          &:nth-child(even) {
            background-color: white;
          }
        }

        td {
          font-weight: 400;
          font-size: 13px;
          color: $black;
          padding: 10px 13px;
          width: calc(100% / 9);
          p{
          font-size: 12px;
          line-height: 15px;

          }

        }
        &:nth-child(even) {
          tr {
            background-color: #f6f8fb;
          }
        }
        &:nth-child(odd) {
          tr {
            background-color: white;
          }
        }
      }
    }
  }
}
